:root {
  --app-height: 100%;
}

#homeArea {
  width: 100vw;
  height: 100vh;
  position: fixed;
  cursor: pointer;
}

#mainFooter {
  position: fixed;
  top: calc(var(--app-height) - 76px);
  left: 10px;
  width: calc(100vw - 20px);
  height: 64px;
  background-color: black;
  display: flex;
  align-items: center;
  border-radius: 10px;
  z-index: 5;
  transition: top 0.40s ease;
}

#homeSlide {
  width: 100vw;
  position: fixed;
  bottom: 11px;
  left: 10px;
  width: calc(100vw - 20px);
  height: calc(var(--app-height) - 32px);
  position: absolute;
  background-color: black;
  transform-origin: bottom;
  transform: scaleY(0);
  transition: transform 0.4s ease;
  border-radius: 10px;
  overflow: scroll;
  overflow-x: hidden;
}

#profilePic {
  margin-left: 25px;
  width: 25px;
  height: 25px;
}

#footTitle {
  color: white;
  margin-left: 15px;
  font-weight: bold;
  font-size: 14px;
}

#upBtnArea {
  width: 80px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
}

#upBtn {
  width: 22px;
  position: absolute;
  right: 20px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

#portfolioGrid {
  width: calc(100% - 60px);
  margin-left: 20px;
  margin-top: 55px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px 40px;
  padding-bottom: 40px;
}

.sitePreview {
  width: 100%;
  padding: 10px;
  height: 310px;
  background-color: rgb(23, 23, 23);
  border-radius: 8px;
  text-align: left;
  cursor: pointer;
}

.siteImgPreview {
  width: 100%;
  height: 230px;
  object-fit: cover;
  border-radius: 4px;
}

.siteName {
  font-weight: bold;
  margin-top: 15px;
  font-size: 18px;
  margin-left: 10px;
}

.siteDescription {
  margin-left: 10px;
  width: calc(100% - 20px);
  margin-top: 5px;
  color: rgb(198, 198, 198);
}

@media only screen and (max-width: 1100px) {
  #portfolioGrid {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  #portfolioGrid {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 500px) {
  .sitePreview {
    height: 260px;
  }

  .siteImgPreview {
    height: 170px;
  }
}